import React from "react";
import { Link } from "gatsby";

import Layout from "../../components/layout";
import SmallBanner from "../../components/smallBanner";

const PrivacyPolicy = () => {
  const lang = "en";

  return (
    <Layout
      seoTitle="Privacy policy"
      lang={lang}
      translationPL="/polityka-prywatnosci/"
      translationDE="/de/datenschutzerklarung/"
    >
      <SmallBanner title="Privacy policy" lang={lang} />

      <section className="default-main">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <p className="bold">1. Data Administrator</p>
              <p>
                Palettenwerk Kozik Sp. z o.o., ul. Przemysłowa 219, 34-240
                Jordanów, Poland, KRS: 0000966492 (further called:
                PalettenWerk).
              </p>
              <p className="bold">2. Contact details</p>
              <p>
                You can contact us at PalettenWerk via email:
                biuro@palettenwerk.pl, or telephone number: +48 18 2773 530, or
                in writing: Palettenwerk Kozik Sp. z o.o., ul. Przemysłowa
                219, 34-240 Jordanów, KRS: 0000966492. PalettenWerk did not
                appoint a data protection inspector.
              </p>
              <p className="bold">
                3. The purposes of processing and its legal basis
              </p>
              <ul>
                <li>
                  Your data will be processed in order for us to be able to
                  provide you with additional information on the job offers. The
                  provision of data is voluntary and allows the provision of
                  marketing information - the provision of data is your consent,
                  provided that this consent has been given by you. If you do
                  not give your consent, your personal data will not be
                  processed for this purpose.
                </li>
                <li>
                  Your data will be processed for direct marketing regarding
                  PalettenWerk own products and services - the legal basis for
                  data processing is your consent, provided that this consent
                  has been given by you. If you do not give your consent, your
                  personal data will not be processed for this purpose.
                </li>
                <li>
                  Your data will be processed in order to provide you with a
                  response onto the contact requests you send us - the legal
                  basis for data processing is your consent, provided that this
                  consent has been given by you. If you do not give your
                  consent, your personal data will not be processed for this
                  purpose
                </li>
              </ul>
              <p className="bold">
                4. The period for which the data will be stored
              </p>
              <p>
                The data will be processed for 2 years or until you withdraw
                your consent to the processing of your personal data for
                marketing purposes.
              </p>
              <p className="bold">5. Data recipients</p>
              <p>
                Your data may be transferred to entities processing personal
                data on behalf of PalettenWerk, in particular to IT services’
                providers and accounting offices - where such entities will
                process data on the basis of an agreement with PalettenWerk and
                only in accordance with PalettenWerk instructions.
              </p>
              <p className="bold">
                6. Your rights regarding the personal data:
              </p>
              <p>You have the following rights:</p>
              <ul>
                <li>have the full access to your data</li>
                <li>request for us to edit your data</li>
                <li>request for us to completely delete your data</li>
                <li>request to limit the processed data,</li>
                <li>remove your consent for our data processing ,</li>
                <li>
                  the right to transfer personal data, i.e. to receive your
                  personal data from PalettenWerk in a file in a commonly used
                  format,
                </li>
                <li>
                  lodge a complaint with the supervisory body dealing with the
                  protection of personal data.
                </li>
              </ul>
              <p>
                In order to use any of the above-mentioned rights, please
                contact PalettenWerk. This can be done, for example, by sending
                an application to the PalettenWerk office address or by e-mail
                to the following address biuro@palettenwerk.pl
              </p>
              <p className="bold">7. Voluntary data provision</p>
              <p>
                Providing personal data for marketing purposes is voluntary.
                Providing personal data for the purpose of considering a
                possible complaint is voluntary, however, failure to do so will
                make it impossible to accept and consider your complaint.
              </p>
              <p className="bold">8. Data protection measures taken</p>
              <ul>
                <li>
                  Data protection is carried out in accordance with the
                  requirements of generally applicable law, and its storage
                  takes place on the secured servers.
                </li>
                <li>
                  We respect the right to privacy and we care about data
                  security. For this purpose, we use secure communication
                  encryption protocol (SSL) on our website.
                </li>
                <li>
                  We have implemented data encryption and access control, thanks
                  to which we can minimize the effects of a possible breach of
                  data security.
                </li>
              </ul>
              <p className="bold">9.Cookie files</p>
              <ul>
                <li>
                  palettenwerk.pl uses cookies. These are small text files sent
                  by a web server and stored by the web browser.. When the
                  browser reconnects with the website, the website recognizes
                  the type of device from which the user connects. These
                  parameters allow the information contained in them to be read
                  only by the server that created them. Therefore, cookies
                  facilitate the use of previously visited websites. The
                  collected information relates to the IP address, type of
                  browser used, language, type of operating system, Internet
                  service provider, information about the time and date,
                  location and information sent to the website via the contact
                  form.
                </li>
                <li>
                  The collected data is used to monitor and check how users use
                  our websites to improve its functioning, ensuring more
                  effective and problem-free navigation. We monitor user
                  information using the Google Analytics tool that records user
                  behavior on the website. Cookies identify the user, which
                  allows the content of the website he/she uses to be adapted to
                  his/her needs. By remembering his/her preferences, it enables
                  the appropriate adjustment of advertisements addressed to
                  him/her. We use cookies to guarantee the highest standard of
                  convenience for our website, and the collected data is used
                  only inside PalettenWerk in order to optimize our activities.
                </li>
                <li>
                  Our website uses cookies:
                  <br />
                  <ul>
                    <li>
                      “obligatory” cookie files, that enable the access to tools
                      from services within the website, eg authentication cookie
                      files that are used for authentication services within the
                      website;
                    </li>
                    <li>
                      cookies used to ensure security, e.g. used to detect fraud
                      in the field of authentication within the website;
                    </li>
                    <li>
                      "performance" cookies, enabling the collection of
                      information on the use of the website pages;
                    </li>
                    <li>
                      "advertising" cookies, enabling us to provide advertising
                      content more tailored to the interests of our website’s
                      users.
                    </li>
                  </ul>
                </li>
                <li>
                  The user may at any time disable or restore the option of
                  collecting cookies by changing the settings in the web
                  browser. The cookie management instructions are available at:
                  http://www.allaboutcookies.org/manage-cookies
                </li>
                <li>
                  Additional personal data, such as e-mail address, are
                  collected only in places where the user has expressly
                  consented to it by completing the form. We store and use the
                  above data only for the purposes necessary to enable specific
                  functions on our website..
                </li>
              </ul>
              <p>
                Your can read more about cookies in our{" "}
                <Link className="color-btn--main" to="/en/cookies-policy/">
                  Cookies policy.
                </Link>
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default PrivacyPolicy;
